import debounce from 'lodash/debounce'
import type {
  ApiResponse,
  Language,
  Mapping,
  MappingsFilters,
  PaginationResponse,
  Rotation,
  RotationQueue,
  RotationUser,
  RotationsFilters,
} from '@/types'

export const useGetRotationsStatistics = async (type: string) => {
  const { data, error } = await useAPIFetch(`/rotations/${type}/statistics`)
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}
export const useGetDefaultRotations = async (query: RotationsFilters) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/rotations/default`, {
    query,
    stringifyOptions: { arrayFormat: 'comma' },
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetCustomRotations = async (query: RotationsFilters) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/rotations/custom`, {
    query,
    stringifyOptions: { arrayFormat: 'comma' },
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useGetRotationGroups = async (type?: string) => {
  const { data, error } = await useAPIFetch<PaginationResponse>('/rotations', { query: { type } })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetRotationsParameters = debounce(
  async () => {
    const { data, error } = await useAPIFetch<PaginationResponse>('/rotations/parameters')
    if (error.value) throw createError(error.value)
    return data.value
  },
  200,
  { leading: true, trailing: false }
)

export const useCreateDefaultRotation = async (rotationDTO: Rotation) => {
  const { data, error } = await useAPIFetch('rotations/default', {
    method: 'POST',
    body: rotationDTO,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}

export const useCreateCustomRotation = async (rotationDTO: Rotation) => {
  const { data, error } = await useAPIFetch('rotations/custom', {
    method: 'POST',
    body: rotationDTO,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}

export const useGetRotationsMappings = debounce(
  async (query: MappingsFilters) => {
    const { data, error } = await useAPIFetch<PaginationResponse>(`/rotations/mappings`, {
      query,
    })

    if (error.value) throw createError(error.value)

    return data.value
  },
  200,
  { leading: true, trailing: false }
)

export const useGetRotationsParametersOperators = debounce(
  async () => {
    const { data, error } = await useAPIFetch('/rotations/parameters/operators')
    if (error.value) throw createError(error.value)
    return data.value as ApiResponse
  },
  200,
  { leading: true, trailing: false }
)

export const useCreateMapping = async (body: Mapping) => {
  const { data, error } = await useAPIFetch('/rotations/mappings', { method: 'POST', body })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useEditMapping = async (body: Mapping) => {
  const { data, error } = await useAPIFetch(`/rotations/mappings/${body.id}`, { method: 'PATCH', body })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useChangeStatusMapping = async (mappingId: number | string) => {
  await useAPIFetch(`/rotations/mappings/${mappingId}/status`, { method: 'PATCH' })
}

export const useChangeOrderMapping = async (body: Mapping) => {
  await useAPIFetch(`/rotations/mappings/change-order`, { method: 'PATCH', body })
}

export const useDeleteMapping = async (mappingId: number) => {
  const { data, error } = await useAPIFetch(`/rotations/mappings/${mappingId}`, { method: 'DELETE' })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useUpdateRotationAgents = async (rotationId: number, users: RotationUser[]) => {
  return await useAPIFetch<ApiResponse>(`/rotations/${rotationId}/users`, {
    method: 'PUT',
    body: { users },
  })
}

export const useUpdateCustomRotation = async (rotationId: number, isEnabled: boolean) => {
  const { data, error } = await useAPIFetch(`/rotations/custom/${rotationId}/`, {
    method: 'PATCH',
    body: { is_enabled: isEnabled },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useDeleteCustomRotation = async (rotationId: number) => {
  const { data, error } = await useAPIFetch(`/rotations/custom/${rotationId}/`, {
    method: 'DELETE',
  })
  if (error.value) throw createError(error.value)
  return data.value
}

// available languages in sdr dropdown
export const useGetRotationQueues = async () =>
  await useAPIFetch<ApiResponse<Pick<Language, 'id' | 'name' | 'code'>[]>>(`/rotations/sdr-queues`)

export const useUpdateRotationQueues = async (rotationId: number, queues: RotationQueue[]) => {
  return await useAPIFetch<ApiResponse>(`/rotations/${rotationId}/sdr-queues`, {
    method: 'PUT',
    body: { queues },
  })
}

export const useGetLinkGeneratorChannels = async () => {
  const { data, error } = await useAPIFetch(`/link-generator/channels`)
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}
